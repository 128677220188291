<template>
  <form @submit.prevent="validate">
    <div class="row d-flex justify-content-center">
      <div class="col-md-12">
        <ValidationObserver ref="card">
          <card title="Add Venue POS Credentials">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-5">
                  <ValidationProvider
                    name="customer"
                    rules="required"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      name="Customer"
                      :error="failed ? 'The Customer field is required' : null"
                      :hasSuccess="passed"
                      label="Customer Name"
                    >
                      <el-select
                        class="select-primary"
                        v-model="customer"
                        placeholder="Customer Name"
                        filterable
                        clearable
                        @change="getVenues(customer.ID)"
                      >
                        <el-option
                          class="select-primary"
                          v-for="item in customers"
                          :key="item.ID"
                          :label="item.NAME"
                          :value="item.ID"
                        >
                        </el-option>
                      </el-select>
                    </fg-input>
                  </ValidationProvider>
                </div>

                <div class="col-md-5">
                  <ValidationProvider
                    name="venue"
                    rules="required"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      name="Venue"
                      :error="failed ? 'The Venue field is required' : null"
                      :hasSuccess="passed"
                      label="Venue"
                    >
                      <el-select
                        class="select-primary"
                        v-model="venue"
                        placeholder="Choose Venue"
                        @change="startPOSCredCard()"
                        filterable
                        clearable
                      >
                        <el-option
                          class="select-primary"
                          v-for="item in venues"
                          :key="item.ID"
                          :label="item.NAME"
                          :value="item.ID"
                        >
                        </el-option>
                      </el-select>
                    </fg-input>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </card>
        </ValidationObserver>
        <VenuePOSCredentials
          ref="VenuePOSCredentials"
          @on-POSCredSubmiting="getVenuePOSData"
          :currentVenuePOS="POSCredentials"
        ></VenuePOSCredentials>
      </div>
      <div class="col-md-3 offset-md-9">
        <l-button type="primary" wide v-on:click="savePOSCredentials"
          >Save POS Credentials</l-button
        >&nbsp;
      </div>
    </div>
  </form>
</template>

<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { Select, Option } from "element-ui";
import PgTable from "src/pages/Dashboard/Tables/PaginatedTables.vue";
import VenuePOSCredentials from "../../Forms/Wizard/CustomerOnboardingWizard/CustomerVenuesWizard/CustomerPosCredentialsCard.vue";
import { initAPI } from "src/gateway/api-instance.js";

extend("required", required);
export default {
  components: {
    PgTable,
    [Select.name]: Select,
    [Option.name]: Option,
    VenuePOSCredentials
  },
  data() {
    return {
      POSCredentials: {
        customerId: "",
        venueId: "",
        posSystem: "",
        IGpath: "",
        ClMerchantID: "",
        ClAccessToken: "",
        ClHost: "",
        Sh4ClientID: "",
        Sh4ClientSecret: "",
        importFrequency: "",
        reportFrequency: ""
      },
      customers: [],
      customer: "",
      venues: [],
      venue: ""
    };
  },
  computed: {
    // This is a computed variable, that it's value is recomputed every time it's used throughout the component.
    Instance() {
      return initAPI(this.$cookies.get("Token"), process.env.VUE_APP_API_URL);
    }
  },
  methods: {
    /*
      - Called when a button with class "submit" is clicked.
      - Goes through every component within a 'validationProvider' tag, and validates all it's rules.
    */
    validate() {
      return this.$refs.form.validate().then(res => {
        this.$emit("on-validated", res);
        return res;
      });
    },
    /*
      - Called when the on-POSCredSubmiting attribute. is triggered.
      - Updates the object with the pos credentials data.
    */
    getVenuePOSData(POSCredentials) {
      this.POSCredentials = POSCredentials;
    },
    /*
      - Called when a venue is chosen, from the drop-down list.
      - re-intiate the object values to start the pos credentials card.
    */
    startPOSCredCard() {
      this.POSCredentials.venueId = this.venue;
      this.POSCredentials.posSystem = "";
      this.POSCredentials.IGpath = "";
      this.POSCredentials.ClMerchantID = "";
      this.POSCredentials.ClAccessToken = "";
      this.POSCredentials.ClHost = "";
      this.POSCredentials.Sh4ClientID = "";
      this.POSCredentials.Sh4ClientSecret = "";
      this.POSCredentials.importFrequency = "";
      this.POSCredentials.reportFrequency = "";
    },
    /*
      - Calls a GET API to get a list of customers from the database.
    */
    getCustomers() {
      this.Instance.get("/simplica/customers").then(response => {
        this.customers = response.data;
      });
    },
    /*
      - Calls a GET API to get a list of venues from the database.
    */
    getVenues() {
      this.POSCredentials.customerId = this.customer;
      this.POSCredentials.venueId = "";
      this.POSCredentials.posSystem = "";
      this.POSCredentials.IGpath = "";
      this.POSCredentials.ClMerchantID = "";
      this.POSCredentials.ClAccessToken = "";
      this.POSCredentials.ClHost = "";
      this.POSCredentials.Sh4ClientID = "";
      this.POSCredentials.Sh4ClientSecret = "";
      this.POSCredentials.importFrequency = "";
      this.POSCredentials.reportFrequency = "";

      this.Instance.get("/simplica/venues?customerId=" + this.customer).then(
        response => {
          this.venues = response.data;
        }
      );
      this.venue = "";
    },
    /*
      - Called when the user, clicks the "Save POS Credentials" button in the UI.
      - Calls a POST API for saving the updated credentials in the database.
    */
    savePOSCredentials() {
      //var obj = JSON.stringify(this.POSCredentials);
      //console.log(this.POSCredentials);

      this.Instance.post("/venues/venue_pos", this.POSCredentials)
        .then(function(response) {
          console.log(response);
        })
        .catch(function(error) {
          console.error(error);
        });
    }
  },
  mounted() {
    this.getCustomers();
  },
  created() {}
};
</script>
<style></style>
